<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-checkbox
        class="quiz-checkbox"
        v-bind="$attrs"
        on-icon="mdi-check"
        off-icon=""
        color="red"
        hide-details
        v-on="$listeners"
    >
        <template v-for="(scope, slot) in $slots" :slot="slot">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </v-checkbox>
</template>

<script>
    export default {
        name: 'QuizCheckbox',
        inheritAttrs: false,
        model: {
            prop: 'input-value',
            event: 'change'
        },
        props: {
            type: null,
            percent: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style lang="scss">
    .quiz-checkbox {
        width: 100%;
        height: 56px;
        border-radius: 12px;
        padding: 16px 23px;
        border: 1px #D5DAE2 solid;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        &__percent-background {
            position: absolute;
            height: 56px;
            width: 100%;
            background: #EDEDED;
            overflow: visible;
            z-index: 0;
        }
        .v-input--selection-controls__input {
            background: #fff;
            display: flex;
            justify-content: center;
            border: 1px solid #616D89;
            border-radius: 4px;
            width: 18px;
            height: 18px;
        }
        &.v-input--selection-controls {
            margin: 0 0 10px;
            &.wrap {
                width: 305px;
                margin-right: 40px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            @media all and (min-width: 768px) {
                // margin-right: 20px;
                margin-top: 10px;
            }
        }
        .v-label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
        }

        .v-icon.v-icon {
            font-size: 16px;
            text-align: center;
        }

        .v-input--selection-controls__ripple {
            left: -13px;
        }
    }
    .v-input--selection-controls__ripple {
        display: none;
    }

    .quiz-checkbox.v-input--is-label-active {
        .v-input--selection-controls__input {
            border: 1px solid #E61E4D;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        }

        .v-icon.v-icon {
            color: #fff !important;
        }
    }
</style>
