<template>
    <v-radio
        class="quiz-radio"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
    export default {
        name: 'QuizRadio',
        inheritAttrs: false
    }
</script>

<style lang="scss">
    $red: #E2231A;

    .quiz-radio {
        width: 100%;
        height: 56px;
        border-radius: 12px;
        padding: 16px 23px;
        border: 1px #D5DAE2 solid;
        
        .v-input--selection-controls__input {
            background: #fff;
            display: flex;
            justify-content: center;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            @media all and (min-width: 768px) {
                margin-right: 10px;
            }
        }
        .v-input--selection-controls__ripple {
            display: none;
        }
        // .mdi-radiobox-blank::before {
        //     content: none;
        // }
        // .mdi-radiobox-marked::before {
        //     content: \2713;
        //     background: $red;
        // }
        &.v-item--active {
            border: 1px #E61E4D solid;
        }
        .v-item--active .v-icon.v-icon {
            width: 16px;
            height: 16px;
            background: $red;
            border-radius: 12px;
            top: 3px;
            left: 3px;
            position: absolute;
            color: $red;
            caret-color: $red;
        }
        .v-label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
        }
    }
</style>
